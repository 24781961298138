/* eslint-disable no-underscore-dangle */
const embedMatomo = () => {
  const matomoUrl = process.env.MATOMO_URL || 'https://subsys.chemotion-repository.net/js/container_G3PH9NrK.js';
  if (matomoUrl) {
    if (!window._mtm) {
      window._mtm = [];
    }
    const mtm = window._mtm;
    mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    const g = document.createElement('script');
    const s = document.getElementsByTagName('script')[0];
    g.async = true;
    g.src = matomoUrl;
    s.parentNode.insertBefore(g, s);
  }
};

export default embedMatomo;
